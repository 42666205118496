<template>
	<div class="chat_window" :style="`height:${clientHeight - 233}px`">
		<el-scrollbar ref="scroll" id="chat">
			<div v-if="msgList.list.length > 0">
				<div v-for="(item, index) in msgList.list" :key="index">
					<div v-if="index == 0 || (index > 0 && $isShowTime(msgList.list[index - 1].addTime, item.addTime))"
						class="time_line">
						{{ $formatChatTime(item.addTime) }}
					</div>
					<!-- 会员发送的信息start -->
					<!-- 文本类型start -->
					<div class="chat_ladder" v-if="item.msgType == 1"
						:class="{ user: item.userType == 1, merchant: item.userType == 2 }">
						<img :src="item.memberAvatar" class="conten_left" v-if="item.userType == 1" />
						<div class="content_right">
							<p>{{ item.userType == 1 ? item.memberName : item.vendorName }}</p>
							<span class="content_text text_type" v-html="JSON.parse(item.msgContent).content"></span>
						</div>
						<img :src="item.vendorAvatar" class="conten_left" v-if="item.userType == 2" />

					</div>
					<!-- 文本类型end -->

					<!-- 图片类型start -->
					<div class="chat_ladder" v-if="item.msgType == 2"
						:class="{ user: item.userType == 1, merchant: item.userType == 2 }">
						<img :src="item.memberAvatar" class="conten_left" v-if="item.userType == 1" />
						<div class="content_right">
							<p>{{ item.userType == 1 ? item.memberName : item.vendorName }}</p>
							<div class="content_text image_type">
								<el-image :src="JSON.parse(item.msgContent).pic"
									:preview-src-list="[JSON.parse(item.msgContent).pic]"
									hide-on-click-modal="true">
									<template #placeholder>
										<div class="image-slot">
											<i class="el-icon-picture-outline"></i>
										</div>
									</template>
								</el-image>
							</div>
						</div>
						<img :src="item.vendorAvatar" class="conten_left" v-if="item.userType == 2" />

					</div>
					<!-- 图片类型end -->

					<!-- 商品类型start -->
					<div class="chat_ladder" v-if="item.msgType == 3"
						:class="{ user: item.userType == 1, merchant: item.userType == 2 }">
						<img :src="item.memberAvatar" class="conten_left" v-if="item.userType == 1" />

						<div class="content_right">
							<p>{{ item.userType == 1 ? item.memberName : item.vendorName }}</p>
							<div class="content_text goods_type">
								<div class="goods_model" :key="index">
									<img :src="JSON.parse(item.msgContent).goodsImage" alt="" />
									<div class="goods_info">
										<div class="goods_info_title">
											{{ JSON.parse(item.msgContent).goodsName }}
										</div>
										<div class="goods_info_bottom">
											<span>{{ L['￥']
											}}{{ JSON.parse(item.msgContent).goodsPrice }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<img :src="item.vendorAvatar" class="conten_left" v-if="item.userType == 2" />

					</div>
					<!-- 商品类型end -->

					<!-- 订单类型start -->
					<div class="chat_ladder" v-if="item.msgType == 4"
						:class="{ user: item.userType == 1, merchant: item.userType == 2 }">
						<img :src="item.memberAvatar" class="conten_left" v-if="item.userType == 1" />

						<div class="content_right">
							<p>{{ item.userType == 1 ? item.memberName : item.vendorName }}</p>
							<div class="content_text order_type">
								<div class="order_title">
									<span class="order_sn">{{ L['订单号'] }}：<i>{{
										JSON.parse(item.msgContent).orderSn
									}}</i></span>
									<span class="order_time">{{
										JSON.parse(item.msgContent).createTime
									}}</span>
								</div>
								<div class="goods_model order_type">
									<img :src="JSON.parse(item.msgContent).goodsImage" alt="" />
									<div class="goods_info">
										<div class="goods_info_title">
											{{ JSON.parse(item.msgContent).goodsName }}
										</div>
										<div class="goods_info_bottom goods_info_bottom_detial">
											<span>{{ L['￥']
											}}{{ JSON.parse(item.msgContent).productShowPrice ?
	JSON.parse(item.msgContent).productShowPrice :
	JSON.parse(item.msgContent).goodsPrice }}</span>
											<span class="goods_info_bottom_ok">{{
												JSON.parse(item.msgContent).orderStateValue
											}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<img :src="item.vendorAvatar" class="conten_left" v-if="item.userType == 2" />

					</div>
					<!-- 订单类型end -->
				</div>
			</div>
			<div v-else>
				<div class="empty_data_left">
					<img src="@/assets/goods/empty_data.png" alt="" />
					<p>{{ L['暂无数据～'] }}</p>
				</div>
			</div>
			<!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
			<div></div>
		</el-scrollbar>
	</div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive, ref, watch } from 'vue'




export default {
	setup() {
		const clientHeight = ref(0)
		const msgList = reactive({//聊天记录列表
			list: []
		})
		const identity = localStorage.getItem('identity')
		const { proxy } = getCurrentInstance()
		const L = proxy.$getCurLanguage()
		const current = ref(1)
		const param = reactive({
			current: 1,
			memberId: '',
			startTime: '',
			endTime: '',
			msgContent: '',
			vendorId: '',
			vendorChoose: '',
		})

		const hasMore = ref(true)
		const scrollTop = ref(false)


		const setCurrent = (n) => {
			param.current = n
		}


		const clearFilter = () => {
			param.current = 1
			param.startTime = ''
			param.endTime = ''
			param.msgContent = ''
		}


		const clearMsgContent = () => {
			param.current = 1
			param.msgContent = ''

		}


		const clearDate = () => {
			param.current = 1
			param.endTime = ''
			param.startTime = ''
		}


		const setQuery = (msgContent, dateRange) => {
			param.current = 1

			param.msgContent = msgContent
			let startTime, endTime

			if (dateRange && dateRange.length) {
				if (dateRange[0]) {
					startTime = [new Date(dateRange[0]).getFullYear(), new Date(dateRange[0]).getMonth() + 1, new Date(dateRange[0]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-') + ' 00:00:00'
				}

				if (dateRange[1]) {
					endTime = [new Date(dateRange[1]).getFullYear(), new Date(dateRange[1]).getMonth() + 1, new Date(dateRange[1]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-') + ' 23:59:59'
				}
			}


			param.startTime = startTime
			param.endTime = endTime

		}

		const clearMsgList = () => {
			hasMore.value = false
			msgList.list = []
		}


		const setId = (memberId, vendorId, storeId) => {
			param.vendorId = vendorId
			param.storeId = storeId
			param.memberId = memberId
		}



		const getList = () => {
			let params = {}
			Object.keys(param).forEach(item => {
				if (param[item] != '') {
					params[item] = param[item]
				}
			})
			proxy.$get(`v3/helpdesk/${identity}/chat/logList`, params).then(res => {
				if (res.state == 200) {
					if (param.current == 1) {
						msgList.list = res.data.list
					} else {
						msgList.list = res.data.list.concat(msgList.list)
					}
					let page = res.data.pagination
					hasMore.value = page.current * page.pageSize < page.total
					if (hasMore.value) {
						param.current++
					}

				}

			})
		}

		//滚动监听
		const scroll = () => {
			let el = proxy.$refs.scroll.wrap
			let timeout = ''
			el.addEventListener('scroll', () => {
				if (timeout) clearTimeout(timeout);
				timeout = window.setTimeout(function () {
					if (el.scrollTop == 0 && hasMore.value) {
						scrollTop.value = true
						getList()
					}
				}, 500);
			}, true);
			(new MutationObserver(() => {
				if (scrollTop.value) {
					el.scrollTop = el.clientHeight + 400
					return
				}
				scrollToBottom(el);
			})).observe(el, { childList: true, subtree: true });
		}

		//滑动至底部方法
		const scrollToBottom = el => {
			el.scrollTop = el.scrollHeight;
			el.scrollTo(0, el.scrollHeight)
		};



		onMounted(() => {
			scroll()
			clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
		})

		return {
			identity,
			clientHeight,
			L,
			scrollTop,
			msgList,
			clearFilter,
			setCurrent,
			setQuery,
			getList,
			setId,
			clearMsgList,
			clearMsgContent,
			clearDate
		}
	}
}
</script>


<style lang="scss">
.chat_window {
	height: 739px;
	/* width: 1442px; */
	padding: 20px 10px 20px 20px;
	background: #f5f5f5;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;

	.time_line {
		margin: 12px auto;
		line-height: 22px;
		text-align: center;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		width: 149px;
		height: 22px;
		background: #eeeeee;
		border-radius: 11px;
	}

	#text_center {
		width: 589px;
		height: 104px;
		background: #ffffff;
		border-radius: 6px;

		.goods_model {
			display: flex;
			justify-content: center;
			align-content: center;
			padding-top: 10px;
			padding-left: 10px;
		}
	}

	.chat_ladder {
		display: flex;
		align-items: flex-end;
		margin-top: 20px;
		margin-bottom: 20px;
		align-items: flex-start;
		box-sizing: border-box;

		&>img {
			width: 38px;
			height: 38px;
			border-radius: 50%;

			&.user_avatar {
				margin-left: 10px;
			}
		}

		.content_right {
			margin-left: 10px;
			margin-right: 10px;


			p {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				margin-bottom: 5px;
			}

			.content_text {
				display: inline-block;
				padding: 11px;
				position: relative;
				max-width: 589px;
				background: #ffffff;
				border-radius: 6px;
			}

			.order_type {
				.goods_info {
					min-width: 300px;

					.goods_info_title {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}
				}

				.order_title {
					display: flex;
					height: 20px;
					justify-content: space-between;
					align-items: flex-start;
					border-bottom: 1px solid #f2f2f2;
					margin-bottom: 8px;

					.order_sn {
						font-size: 12px;
						color: #999999;
						font-family: Microsoft YaHei;

						i {
							font-style: normal;
							color: #666666;
						}
					}

					.order_time {
						color: #999999;
					}
				}
			}

			.goods_type {
				min-width: 300px;
			}

			.image_type {
				min-height: 100px;

				img {
					max-width: 200px;
				}

				.image-slot {
					width: 200px;
					height: 200px;
				}
			}

			.text_type {
				max-width: 360px;
				word-break: break-all;
			}
		}
	}

	.user {
		justify-content: flex-start;

		.content_text {
			background: #ffffff;

			border-radius: 6px;
		}
	}

	.merchant {
		padding-right: 20px;
		justify-content: flex-end;

		p {
			text-align: right;
		}

		.content_text {
			.tiny_triangle {
				position: absolute;
				right: -9px;
				width: 0;
				height: 0;
				border-right: 14px solid transparent;
				border-bottom: 13px solid #fff;
			}

			background: #fff;
			border-radius: 6px;
		}

		.msg_read {
			align-self: flex-end;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
		}

		.msg_not_read {
			align-self: flex-end;
			height: 100%;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
		}
	}
}

.empty_data_left {
	height: 300px;
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 80px;
	}

	p {
		margin-top: 15px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #d5d5d5;
	}
}

.goods_model {
	display: flex;

	&>img {
		width: 84px;
		height: 84px;
		background: #707070;
		border-radius: 6px;
	}

	.goods_info {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 450px;

		.goods_info_title {
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			padding-right: 10px;
		}

		.goods_info_bottom {
			display: flex;
			justify-content: space-between;

			span:first-child {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #e2231a;
			}

			span:nth-child(2) {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #e2231a;
			}
		}
	}
}
</style>